@import 'styles/variables';

.header {
    height: 65px;
    min-height: 65px;
    padding: 10px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #c6cbd9;
    color: #000;
    z-index: 1400;
    box-shadow: 0px 100px 80px rgba(178, 183, 219, 0.05),
        0px 41.7776px 33.4221px rgba(178, 183, 219, 0.0359427),
        0px 22.3363px 17.869px rgba(178, 183, 219, 0.0298054),
        0px 12.5216px 10.0172px rgba(178, 183, 219, 0.025),
        0px 6.6501px 5.32008px rgba(178, 183, 219, 0.0201946),
        0px 2.76726px 2.21381px rgba(178, 183, 219, 0.0140573);

    h1 {
        font-family: $monserrat;
        font-size: 22px;
        font-weight: 400;
        margin: 0 1.5rem;
        cursor: default;
    }

    .logoBlock {
        display: flex;
        align-items: center;

        .logo {
            height: 23.5px;
        }
    }

    .settingsBlock {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
            color: #9a9aaf;
            cursor: pointer;
            &:hover {
                color: #535362;
            }
        }

        #settings {
            margin-right: 44px;
        }

        #notifications {
            margin-right: 24px;
        }
    }

    .userBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 30px;

        &__image {
            height: 32px;
            width: 32px;
            margin-right: 8px;
            background: #069ff6;
            border-radius: 100%;
            color: #fff;
            font-size: 15px;
            line-height: 32px;
            font-weight: 700;
            text-align: center;
            cursor: default;
        }

        &__data {
            font-size: 14px;
            cursor: default;
            p {
                font-weight: 600;
                margin: 0;
            }
            span {
                font-size: 12px;
                color: #9a9aaf;
            }
        }

        button {
            padding: 0;
            margin-left: 10px;
            transition: transform 200ms ease-in-out;
        }

        .openMenuButton {
            transform: rotate(180deg);
        }

        svg {
            font-size: 32px;
            margin: 0;
        }
    }
}

.menuBlock {
    &__list li {
        font-family: $monserrat;
    }
}
